<template>
  <div class="publish-device publish-need">
    <el-form ref="form" :model="form" :rules="rules">
      <Header />
      <div class="container">
        <div class="w-1200">
          <div class="publish-need-title">发布求租信息</div>
          <div class="base-step">
            <div class="step-item" :class="{ active: true }"><span class="num">1</span><span class="name">编写信息</span></div>
            <i class="divider" :class="{ active: false }"></i>
            <div class="step-item"><span class="num">2</span><span class="name">申请发布</span></div>
            <i class="divider"></i>
            <div class="step-item"><span class="num">3</span><span class="name">平台审核</span></div>
          </div>
          <section class="form">
            <div class="form-title">基本信息</div>
            <div class="form-main">
              <div class="form-item">
                <div class="form-label"><span class="icons">*</span>选择分类</div>
                <el-form-item prop="inputs.type_id">
                  <el-cascader
                  ref="cascader"
                  v-model="form.inputs.type_id"
                  style="width: 350px"
                  :options="checkList"
                  :props="{ checkStrictly: true, value: 'type_id', label: 'type_name' }"
                  clearable
                  @change="handleChange"></el-cascader>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label"><span class="icons">*</span>适用工况</div>
                <el-form-item prop="inputs.seekrent_type">
                  <el-checkbox-group v-model="form.inputs.seekrent_type" :max="1">
                    <el-checkbox label="房建工程"></el-checkbox>
                    <el-checkbox label="市政工程"></el-checkbox>
                    <el-checkbox label="公路工程"></el-checkbox>
                    <el-checkbox label="水利工程"></el-checkbox>
                    <el-checkbox label="园林工程"></el-checkbox>
                    <el-checkbox label="桥隧工程"></el-checkbox>
                    <el-checkbox label="其他"></el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label"><span class="icons">*</span>设备使用地址</div>
                <el-form-item prop="inputs.address">
                  <el-input id="test" v-model="form.inputs.address" placeholder="请输入地址…" style="width: 430px"></el-input>
                  <el-button type="warning" class="dinwei" @click="$refs.map.setPlace(form.inputs.address)">定位</el-button>
                  <div id="searchResultPanel" style="border: 1px solid #c0c0c0; width: 500px; height: auto; display: none"></div>
                </el-form-item>
                <Map class="map" style="height: 300px" ref="map" @getAddress="getAddress" :searchAddress="form.inputs.address" @getProvince="getProvince"> </Map>
              </div>
              <div class="form-item">
                <div class="form-label"><span class="icons">*</span>租赁时间</div>
                <el-form-item prop="inputs.rental_num">
                  <el-input type="number" v-model="form.inputs.rental_num" placeholder="请输入…" style="width: 120px; margin-right: 36px" @blur="BlurText($event)"></el-input>
                  <el-radio v-model="form.inputs.rental_type" label="0">按日</el-radio>
                  <el-radio v-model="form.inputs.rental_type" label="1">按月</el-radio>
                  <el-radio v-model="form.inputs.rental_type" label="2">按半年</el-radio>
                  <el-radio v-model="form.inputs.rental_type" label="3">按年</el-radio>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label"><span class="icons">*</span>设备品牌</div>
                <el-form-item prop="inputs.device_brand">
                  <el-input v-model="form.inputs.device_brand" placeholder="请输入品牌名称…" style="width: 430px"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label"><span class="icons">*</span>标题名称</div>
                <el-form-item prop="inputs.name">
                  <el-input v-model="form.inputs.name" placeholder="请输入标题名称…" style="width: 430px"></el-input>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label">求租内容</div>
                <el-form-item>
                  <my-editor ref="editor" class="editor" @gethtml="gethtml"></my-editor>
                </el-form-item>
              </div>
              <div class="form-item">
                <div class="form-label">图片</div>
                <el-form-item>
                  <el-upload
                  ref="img"
                  action="http://114.67.170.224:21880/api/upload"
                  list-type="picture-card"
                  :on-success="handleAvatarSuccess"
                  :on-remove="remove"
                  >
                    <i class="el-icon-plus"></i>
                    <div>添加上传图片</div>
                  </el-upload>
                </el-form-item>
              </div>
            </div>
          </section>
          <section class="form-btn-group">
            <el-form-item>
              <el-button class="btn-item btn-border" :plain="true" @click="cancel('form')">取消</el-button>
              <el-button class="btn-item" type="success" :plain="true" @click="apply">申请发布</el-button>
            </el-form-item>
          </section>
          <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false">
            <span class="back">提交成功,请选择返回页面</span>
            <span slot="footer" class="dialog-footer">
              <el-button class="backhome" type="success" @click="gohome">返回首页</el-button>
              <el-button class="backNeed" type="primary" @click="goMyDevice">需求管理</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
      <Footer />
      <right-nav />
    </el-form>
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import RightNav from '@/components/rightNav'
import { publishNeed,typeList } from '@/api/publish.js'
import kits from '@/kits/index.js'
import myEditor from './myEditor.vue'
import { getuser } from '@/api/user'
import Map from '@/components/map.vue'
export default {
  components: {
    Header,
    Footer,
    RightNav,
    myEditor,
    Map
  },
  data() {
    return {
      radio: '1',
      checkList: [],
      options: [],
      input: '',
      value: {},
      dialogVisible: false,
      form: {
        inputs: {
          consumer_id: '',
          type_id: null,
          seekrent_type: [],
          address: '',
          rental_num: null,
          rental_type: '0',
          device_brand: '',
          name: '',
          seekrent_content: '',
          seekrent_img: [],
          longitude: '',
          latitude: '',
          audit_state: 1,
          province:'',
          city: ''
        }
      },
      rules: {
        inputs: {
          type_id: [{ required: true, message: '请选择分类', trigger: 'change' }],
          seekrent_type: [{ type: 'array', required: true, message: '请选择适用工况', trigger: 'change' }],
          address: [{ required: true, message: '请输入地址', trigger: 'change' }],
          rental_num: [{ required: true, message: '请输入时间', trigger: 'blur' }],
          device_brand: [{ required: true, message: '请输入设备品牌', trigger: 'blur' }],
          name: [{ required: true, message: '请输入标题名称', trigger: 'blur' }]
        }
      }
    }
  },
  mounted() {
    // 获取用户id
    let _user = getuser()
    this.form.inputs.consumer_id = _user.id
    typeList()
      .then(res => {
        if (res.status != 200) return
        this.checkList = kits.arr.tree_set(res.data, 'parent_id', 'type_id')
        console.log(this.checkList)
      })
      .catch(err => {
        return err
      })
      this.searchResult();

  },
  methods: {
    // 输入只能为正整数
    BlurText(e) {
      let boolean = new RegExp("^[1-9][0-9]*$").test(e.target.value)
      if(!boolean) {
        this.$message.warning("请输入正整数")
        e.target.value = ''
      }
    },
    // 获取富文本传送数据
    gethtml(html) {
      this.form.inputs.seekrent_content = html
    },
    // 地图地址
    getAddress(address) {
      this.form.inputs.address = address
    },
    //获取省份
    getProvince(province,city) {
      // console.log(province,city);
      this.form.inputs.province = province
      this.form.inputs.city = city
    },
    // 图片上传成功
    handleAvatarSuccess(res) {
      this.form.inputs.seekrent_img.push(res.data.value)

    },
    // 图片删除
    remove(file) {
      let removefile = file.response.data.value
      let removearr = []
      removearr.push(removefile)
      for(let i = 0;i< removearr.length;i++) {
        for(let j = 0;j < this.form.inputs.seekrent_img.length;j++) {
          if(removearr[i] == this.form.inputs.seekrent_img[j]) {
            this.form.inputs.seekrent_img.splice(j,1)
          }
        }
      }
    },
    // 取消
    cancel(form) {
      this.$refs[form].resetFields() //重置表单数据
      this.$refs.img.clearFiles()
      this.form.inputs.seekrent_img.splice(0, this.form.inputs.seekrent_img.length)
      // 清除富文本内容
      this.$refs.editor.html = ''
      // 跳转首页
      this.$router.push('/home')
    },
    // 申请成功
    apply() {
      // 获取经纬度
      this.form.inputs.longitude =  this.$refs.map.coordinates.lng
      this.form.inputs.latitude = this.$refs.map.coordinates.lat
      // publishNeed(this.form)
      this.$refs.form.validate(value => {
        // console.log(value)
        if (value) {
          this.dialogVisible = true
          this.$message({
            message: '申请发布成功',
            type: 'success'
          })
          for (let i = 0; i <= this.form.inputs.seekrent_type.length - 1; i++) {
            if (this.form.inputs.seekrent_type[i] == '房建工程') {
              this.form.inputs.seekrent_type[i] = 1
            } else if (this.form.inputs.seekrent_type[i] == '市政工程') {
              this.form.inputs.seekrent_type[i] = 2
            } else if (this.form.inputs.seekrent_type[i] == '公路工程') {
              this.form.inputs.seekrent_type[i] = 3
            } else if (this.form.inputs.seekrent_type[i] == '水利工程') {
              this.form.inputs.seekrent_type[i] = 4
            } else if (this.form.inputs.seekrent_type[i] == '园林工程') {
              this.form.inputs.seekrent_type[i] = 5
            } else if (this.form.inputs.seekrent_type[i] == '桥隧工程') {
              this.form.inputs.seekrent_type[i] = 6
            } else if (this.form.inputs.seekrent_type[i] == '其他') {
              this.form.inputs.seekrent_type[i] = 7
            }
          }
          let permission = this.form.inputs.seekrent_type.join(',')
          this.form.inputs.seekrent_type = permission
          publishNeed(this.form)
        } else {
          this.$message({
            message: '数据不能为空',
            type: 'warning'
          })
        }
      })
    },
    // 返回首页
    gohome() {
      this.dialogVisible = false
      this.$router.push('/home')
    },
    // 返回我的设备
    goMyDevice() {
      this.dialogVisible = false
      this.$router.push('/personalCenter/needManage')
      // this.$router.push({path:'/personalCenter/needManage',query: {id: this.form.inputs.consumer_id }})
    },
    // 选择器变化事件
    handleChange() {
      let nodesInfo = this.$refs.cascader.getCheckedNodes()
      this.form.inputs.type_id = nodesInfo[0].data.type_id
      // this.$refs.cascader.dropDownVisible = false;
    },
    searchResult() {
            var ac = new window.BMapGL.Autocomplete({ input: "test" });
            console.log("ac", ac);
			let that = this
            ac.addEventListener("onhighlight", function (e) {
                //鼠标放在下拉列表上的事件
                var str = "";
                var _value = e.fromitem.value;
                var value = "";
                if (e.fromitem.index > -1) {
                    value =
                        _value.province +
                        _value.city +
                        _value.district +
                        _value.street +
                        _value.business;
                }
                str =
                    "FromItem<br />index = " +
                    e.fromitem.index +
                    "<br />value = " +
                    value;
                value = "";
                if (e.toitem.index > -1) {
                    _value = e.toitem.value;
                    value =
                        _value.province +
                        _value.city +
                        _value.district +
                        _value.street +
                        _value.business;
                }
                str +=
                    "<br />ToItem<br />index = " +
                    e.toitem.index +
                    "<br />value = " +
                    value;
                document.getElementById("searchResultPanel").innerHTML = str;
            });

            var myValue;
            ac.addEventListener("onconfirm", function (e) {
                //鼠标点击下拉列表后的事件
				document.getElementById("searchResultPanel").innerHTML = ''
                var _value = e.item.value;
                myValue =
                    _value.province +
                    _value.city +
                    _value.district +
                    _value.street +
                    _value.business;
                document.getElementById("searchResultPanel").innerHTML =
                    "onconfirm<br />index = " +
                    e.item.index +
                    "<br />myValue = " +
                    myValue;
				that.$refs.map.setPlace(myValue)
            });
        },
  }
}
</script>

<style >
.backhome {
  margin-right: 150px;
}
.backNeed {
  margin-right: 30px;
}
.back {
  font-size: 26px;
}
.editor {
  /* height: 300px; */
}
.icon {
  width: 10px;
}
.icons {
  color: red;
}
.el-cascader-panel .el-radio{
        width: 100%;
        height: 100%;
        z-index: 10;
        position: absolute;
        top: 10px;
        right: 10px;
    }
    .el-cascader-panel .el-radio__input{
        visibility: hidden;
    }
    .el-cascader-panel .el-cascader-node__postfix {
        top: 10px;
    }
</style>
